import React, { useEffect, useState } from "react";
import "../../styles/table.css";
import "../../styles/activity.css";

import Search from "../../components/Search";
import Filter from "../../components/Filter";

import IcPolygonUp from "../../asset/svg/polygonUp";
import IcPolygonDown from "../../asset/svg/polygonDown";
import IcEye from "../../asset/svg/eye"
import { useDispatch, useSelector } from "react-redux";
import { getTodayActivity } from "../../store/actions";
import ModalPDFViewer from "../../components/ModalPDFViewer";
// import { useNavigate } from "react-router-dom";

function TodayActivity() {
  // const navigate = useNavigate();
  const dispatch = useDispatch()

  const { todayActivity } = useSelector(state => state.resumeMedisState)

  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]); //all data
  const [dataSearch, setDataSearch] = useState([]); //all search by name data
  const [dataRender, setDataRender] = useState([]); // data per page

  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const itemPerPage = 10;
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [arrayPage, setArrrayPage] = useState([]);

  const [searchName, setSearchName] = useState("");

  const [divisiModal, showDivisiModal] = useState(false);
  const [divisi, setDivisi] = useState([]);
  const [selectedDivisi, setSelectedDivisi] = useState("");

  const [faskesModal, showFaskesModal] = useState(false);
  const [faskes, setFaskes] = useState([]);
  const [selectedFaskes, setSelectedFaskes] = useState("");

  const [modalPdf, setModalPdf] = useState(false)
  const [urlPdf, setUrlPdf] = useState("");

  // setData
  useEffect(() => {
    dispatch(getTodayActivity())
    setDivisi(["Semua Divisi"]);
    setFaskes(["Semua Faskes"]);
    // eslint-disable-next-line
  }, []); // set all data here

  useEffect(() => {
    if(todayActivity){
      setRawData(todayActivity)
      const allDivisi = ["Semua Divisi"];
      const allFaskes = ["Semua Faskes"];
      todayActivity.forEach((el) => {
        if (!allDivisi.includes(el.department)) {
          allDivisi.push(el.department);
        }
        if (!allFaskes.includes(el.facilityName)) {
          allFaskes.push(el.facilityName);
        }
      });
      setDivisi(allDivisi);
      setFaskes(allFaskes)
  }
  // eslint-disable-next-line
  },[todayActivity])

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  useEffect(() => {
    setSelectedDivisi(divisi[0]);
    setSelectedFaskes(faskes[0]);
  }, [divisi, faskes]);

  //sort handle
  useEffect(() => {
    if (searchName === "") {
      if (sortType === "ASC") {
        setData([...data].sort((a, b) => a[sortBy].localeCompare(b[sortBy])));
      } else if (sortType === "DESC") {
        setData([...data].sort((a, b) => b[sortBy].localeCompare(a[sortBy])));
      }
    } else {
      if (sortType === "ASC") {
        setDataSearch(
          [...dataSearch].sort((a, b) => a[sortBy].localeCompare(b[sortBy]))
        );
      } else if (sortType === "DESC") {
        setDataSearch(
          [...dataSearch].sort((a, b) => b[sortBy].localeCompare(a[sortBy]))
        );
      }
    }
    // eslint-disable-next-line
  }, [sortType, sortBy]);

  const sortHandle = async (field) => {
    if (sortBy !== field) {
      setSortBy(field);
    }
    if (sortBy === field) {
      sortType === "ASC" ? await setSortType("DESC") : await setSortType("ASC");
    } else {
      await setSortType("ASC");
    }
  };

  //pagination
  useEffect(() => {
    if (searchName === "") {
      setTotalPage(Math.ceil(data.length / itemPerPage));
      setDataRender(
        data.slice(
          (activePage - 1) * itemPerPage,
          (activePage - 1) * itemPerPage + itemPerPage
        )
      );
    } else {
      setTotalPage(Math.ceil(dataSearch.length / itemPerPage));
      setDataRender(
        dataSearch.slice(
          (activePage - 1) * itemPerPage,
          (activePage - 1) * itemPerPage + itemPerPage
        )
      );
    }
    // eslint-disable-next-line
  }, [data, dataSearch, activePage]);

  useEffect(() => {
    const array = [];
    if (activePage > 1) array.push(activePage - 1);
    array.push(activePage);
    if (totalPage > activePage) array.push(activePage + 1);
    if (array.length < 3 && totalPage > activePage + 1)
      array.push(activePage + 2);
    if (array.length < 3 && activePage > 2) array.unshift(activePage - 2);
    setArrrayPage(array);
  }, [totalPage, activePage]);

  //search by name
  useEffect(() => {
    if (searchName === "") {
      setDataSearch(data);
    } else if (data.length) {
      var regexp = new RegExp(searchName, "gi");
      const newData = data.filter((el) => {
        return el.name.match(regexp) !== null;
      });
      setDataSearch(newData);
      setActivePage(1);
    }
    // eslint-disable-next-line
  }, [searchName]);

  //filter
  const selectFilter = (filtername, filter) => {
    switch (filtername) {
      case "divisi":
        showDivisiModal(false);
        setSelectedDivisi(filter);
        break;

      case "faskes":
        showFaskesModal(false);
        setSelectedFaskes(filter);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (
      selectedDivisi !== "Semua Divisi" ||
      selectedFaskes !== "Semua Faskes"
    ) {
      setSearchName("");
      let newData = searchName !== "" ? [...dataSearch] : [...rawData];
      if (selectedDivisi !== "Semua Divisi") {
        newData = rawData.filter((el) => {
          return el.department === selectedDivisi;
        });
      } else {
        newData = [...rawData];
      }
      if (selectedFaskes !== "Semua Faskes") {
        newData = newData.filter((el) => {
          return el.facilityName === selectedFaskes;
        });
      }
      setActivePage(1);
      searchName === "" ? setData(newData) : setDataSearch(newData);
    } else {
      setSearchName("");
      setActivePage(1);
      setData(rawData);
    }
    // eslint-disable-next-line
  }, [selectedDivisi, selectedFaskes]);

  const closeModal = () => {
    showFaskesModal(false)
    showDivisiModal(false)
  }

  return (
    <div className="bgcontent">
      <div className="activity">
        <div className="whiteCard">
          <div className="title">Total Karyawan Sakit</div>
          <div className="amount">{rawData.length}</div>
        </div>
        <div className="filter">
          <Search
            searchName={searchName}
            setSearchName={setSearchName}
            placeholder={"Pencarian"}
          />
          <Filter
            filterName={'divisi'}
            modal={divisiModal}
            closeModal={closeModal}
            showModal={() => {
              showFaskesModal(false);
              divisiModal ? showDivisiModal(false) : showDivisiModal(true);
            }}
            item={divisi}
            selectedItem={selectedDivisi}
            selectFilter={selectFilter}
          />
          <Filter
            filterName={'faskes'}
            modal={faskesModal}
            closeModal={closeModal}
            showModal={() => {
              showDivisiModal(false);
              faskesModal ? showFaskesModal(false) : showFaskesModal(true);
            }}
            item={faskes}
            selectedItem={selectedFaskes}
            selectFilter={selectFilter}
          />
          <div
            className="reset"
            onClick={() => {
              setSearchName("");
              setSelectedDivisi("Semua Divisi");
              setSelectedFaskes("Semua Faskes");
            }}
          >
            RESET FILTER
          </div>
        </div>
        <table>
          <thead className="tablehead">
            <tr>
              <th className="columnnama">
                <div
                  className="columntitle ml"
                  onClick={() => sortHandle("name")}
                >
                  <div>NAMA</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "name" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "name" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div className="columntitle" onClick={() => sortHandle("department")}>
                  <div>DIVISI</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "department" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "department" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div
                  className="columntitle"
                  onClick={() => sortHandle("position")}
                >
                  <div>JABATAN</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "position" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "position" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div className="columntitle" onClick={() => sortHandle("phoneNumber")}>
                  <div>NO HP</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "phoneNumber" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "phoneNumber" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div className="columntitle" onClick={() => sortHandle("facilityName")}>
                  <div>FASKES</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "facilityName" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "facilityName" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div
                  className="columntitle"
                  onClick={() => sortHandle("conclusion")}
                >
                  <div>KELUHAN</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "conclusion" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "conclusion" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
              <th className="column">
                <div className="columntitle" onClick={() => sortHandle("dayBreak")}>
                  <div>HASIL</div>
                  <div className="sort">
                    <IcPolygonUp
                      active={sortBy === "dayBreak" && sortType === "ASC"}
                    />
                    <IcPolygonDown
                      active={sortBy === "dayBreak" && sortType === "DESC"}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataRender?.map((el, idx) => {
              return (
                <tr className="tablebody" key={idx}>
                  <th
                    className="columnbodynama"
                    style={{ paddingLeft: "15px" }}
                  >
                    {el.name}
                  </th>
                  <th className="columnbody">{el.department}</th>
                  <th className="columnbody">{el.position}</th>
                  <th className="columnbody">{el.phoneNumber && el.phoneNumber[0] === "+" ? `0${el.phoneNumber.slice(3)}` : el.phoneNumber}</th>
                  <th className="columnbody">{el.facilityName}</th>
                  <th className="columnbody">{el.conclusion}</th>
                  <th className="columnbody" style={{ color: "#EC3939" }}>
                    <div className="row">
                      <div>{`Istirahat ${el.dayBreak} hari`}</div>
                      <div
                        style={{
                          cursor: "pointer",
                          paddingLeft: "5px",
                          paddingTop: "2px"
                        }}
                        onClick={() => {
                          setUrlPdf(el.medicalCertificate.fileUrl)
                          setModalPdf(true)
                        }}
                      >
                        <IcEye />
                      </div>
                    </div>
                  </th>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="tablefooter">
            <tr className="row">
              <td>
              <button
                className="pagination"
                onClick={() => setActivePage(activePage - 1)}
                disabled={activePage === 1}
              >
                {"<"}
              </button>
              </td>
              {totalPage > 0 &&
                arrayPage.map((el, idx) => {
                  return (
                    <td
                      key={idx}
                      className="pagination"
                      style={{
                        backgroundColor:
                          activePage === el ? "#558EFF" : "transparent",
                        color: activePage === el ? "#FFFFFF" : "#25282B",
                      }}
                      onClick={() => setActivePage(el)}
                    >
                      {el}
                    </td>
                  );
                })}
                <td>
              <button
                className="pagination"
                onClick={() => setActivePage(activePage + 1)}
                disabled={activePage >= totalPage}
              >
                {">"}
              </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {modalPdf && (
        <ModalPDFViewer title={"Surat Sakit"} modal={setModalPdf} url={urlPdf} />
      )}
    </div>
  );
}

export default TodayActivity;
