import React, { useEffect, useState } from "react";
import RoundedSearch from "../../components/RoundedSearch";

import IcClose from "../../asset/svg/ic_close";
import IcDocument from "../../asset/svg/document"

import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../store/actions";
import ModalPDFViewer from "../../components/ModalPDFViewer";
import Filter from "../../components/Filter";

import { JsonToExcel } from "react-json-to-excel";

export default function ModalResume({ setModal }) {
  const dispatch = useDispatch()
  const [modalData, setModalData] = useState({})
  const [searchName, setSearchName] = useState("");
  const [dataRender, setDataRender] = useState([])
  const [modalPdf, setModalPdf] = useState(false)
  const [modalPdfFile, setModalPdfFile] = useState("")
  const [modalPdfType, setModalPdfType] = useState("")
  const [modalPdfTitle, setModalPdfTitle] = useState("")
  const [typeEvent, setTypeEvent] = useState("")

  const [modalFilterHasil, setModalFilterHasil] = useState(false)
  const [modalFilterHadir, setModalFilterHadir] = useState(false)
  const [itemFilterHasil, setItemFilterHasil] = useState(["Semua Hasil", "Fit", "Unfit", "Fit With Medical Note"])
  const itemFilterHadir = ["Selesai Diperiksa", "Tidak Hadir"]
  const [selectedFilterHasil, setSelectedFilterHasil] = useState("Semua Hasil")
  const [selectedFilterHadir, setSelectedFilterHadir] = useState("Selesai Diperiksa")

  const [typeTableFull, setTypeTabelFull] = useState(true)
  const [dataExcel, setDataExcel] = useState([])

  const { detailEvents } = useSelector(state => state.eventState)

  function createDataExcel(){
    if (detailEvents.participant.length){
      const newDataExcel = []
      detailEvents.participant.forEach((el) => {
        if(el.RM && el.RM.lpa && el.RM.lpa.MCUAnamnesaID){
          const dataAnamnesa = el.RM.lpa.MCUAnamnesaID
          const dataPhysicalExam = el.RM.lpa.MCUPhysicalExamID
          newDataExcel.push({
            "NO.":  newDataExcel.length + 1,
            "NAMA" :  el.name,
            "TGL LAHIR": el.dob,
            "GENDER": el.gender,
            "Keluhan Sekarang": dataAnamnesa.perceivedDisease || null,
            "Penyakit Yang Pernah Diderita": dataAnamnesa.diseaseSuffered || null,
            "Riwayat Operasi": dataAnamnesa.surgeryHistory || null,
            "Riwayat Kecelakaan": dataAnamnesa.accidentHistory || null,
            "Riwayat Penyakit Keluarga": dataAnamnesa.diseaseFamilyHistory || null,
            "Alergi": dataAnamnesa.alergi || null,
            "Merokok": dataAnamnesa.amountSmoking ? dataAnamnesa.smoking + ` (${dataAnamnesa.amountSmoking})` : dataAnamnesa.smoking,
            "Alkohol": dataAnamnesa.amountAlcohol ? dataAnamnesa.alcohol + ` (${dataAnamnesa.amountAlcohol})` : dataAnamnesa.alcohol,
            "Kopi": dataAnamnesa.amountCoffee ? dataAnamnesa.coffee + ` (${dataAnamnesa.amountCoffee})` : dataAnamnesa.coffee,
            "Olahraga": dataAnamnesa.typeSport ? dataAnamnesa.sport + ` (${dataAnamnesa.typeSport})` : dataAnamnesa.sport,
            "Tinggi Badan": dataPhysicalExam.height || null,
            "Berat Badan": dataPhysicalExam.weight || null,
            "BMI": dataPhysicalExam.BMI || null,
            "Tensi": `${dataPhysicalExam.systole}/${dataPhysicalExam.dyastole}`,
            "Nadi": dataPhysicalExam.heartRate || null,
            "Suhu": dataPhysicalExam.temperatur || null,
            "Nafas": dataPhysicalExam.spo2 || null,
            "Visus Kanan": dataPhysicalExam.eye ? dataPhysicalExam.eye.leftVision : null,
            "Visus Kiri": dataPhysicalExam.eye ? dataPhysicalExam.eye.leftVision : null,
            "Buta Warna": dataPhysicalExam.eye ? dataPhysicalExam.eye.colorBlind : null,
            "Serumen": dataPhysicalExam.ear ? dataPhysicalExam.ear.serumen : null,
            "Membran Timpani": dataPhysicalExam.ear ? dataPhysicalExam.ear.membranTimpani : null,
            "Fungsi Pendengaran": dataPhysicalExam.ear ? dataPhysicalExam.ear.hearingFunction : null,
            "Mukosa": dataPhysicalExam.nose ? dataPhysicalExam.nose.mukosa : null,
            "Konka": dataPhysicalExam.nose ? dataPhysicalExam.nose.konka : null,
            "Karang Gigi": dataPhysicalExam.mouth ? dataPhysicalExam.mouth.tartar : null,
            "Caries": dataPhysicalExam.mouth ? dataPhysicalExam.mouth.carries : null,
            "Gigi Tanggal": dataPhysicalExam.mouth ? dataPhysicalExam.mouth.teethLost : null,
            "Tonsil": dataPhysicalExam.neck ? dataPhysicalExam.neck.tonsil : null,
            "Faring": dataPhysicalExam.neck ? dataPhysicalExam.neck.faring : null,
            "Tiroid": dataPhysicalExam.neck ? dataPhysicalExam.neck.tiroid : null,
            "Bendungan Vena": dataPhysicalExam.neck ? dataPhysicalExam.neck.venousDam : null,
            "Trachea": dataPhysicalExam.raspiratory ? dataPhysicalExam.raspiratory.trachea : null,
            "Gerakan Pernafasan": dataPhysicalExam.raspiratory ? dataPhysicalExam.raspiratory.movement : null,
            "Auskultasi": dataPhysicalExam.raspiratory ? dataPhysicalExam.raspiratory.auskultasi : null,
            "Perkusi": dataPhysicalExam.raspiratory ? dataPhysicalExam.raspiratory.perkusi : null,
            "Vena-vena": dataPhysicalExam.cardioVaskular ? dataPhysicalExam.cardioVaskular.vena : null,
            "Bunyi Jantung": dataPhysicalExam.cardioVaskular ? dataPhysicalExam.cardioVaskular.heartSound : null,
            "Edema": dataPhysicalExam.cardioVaskular ? dataPhysicalExam.cardioVaskular.edema : null,
            "Abdomen": dataPhysicalExam.digestivus ? dataPhysicalExam.digestivus.abdomen : null,
            "Hati": dataPhysicalExam.digestivus ? dataPhysicalExam.digestivus.heart : null,
            "Limpa": dataPhysicalExam.digestivus ? dataPhysicalExam.digestivus.limpa : null,
            "Bising Usus": dataPhysicalExam.digestivus ? dataPhysicalExam.digestivus.bowel : null,
            "Anggota Gerak Atas": dataPhysicalExam.limbSystem ? dataPhysicalExam.limbSystem.upper : null,
            "Anggota Gerak Bawah": dataPhysicalExam.limbSystem ? dataPhysicalExam.limbSystem.bottom : null,
            "Tulang Belakang": dataPhysicalExam.limbSystem ? dataPhysicalExam.limbSystem.spine : null,
            "Refleks Fisiologis": dataPhysicalExam.limbSystem ? dataPhysicalExam.limbSystem.fisiologis : null,
            "Refleks Patologis": dataPhysicalExam.limbSystem ? dataPhysicalExam.limbSystem.patologis : null,
            "Ginjal": dataPhysicalExam.genitourinaria ? dataPhysicalExam.genitourinaria.kidney : null,
            "Kandung Kemih": dataPhysicalExam.genitourinaria ? dataPhysicalExam.genitourinaria.bladder : null,
            "KESIMPULAN MCU": dataPhysicalExam.result || null,
            "SARAN MCU": dataPhysicalExam.suggestion || null,
            "HASIL MCU": el.RM.lpa.finalExamFinally || null,
          })
        }
      })
      setDataExcel(newDataExcel)
    }
  }


  useEffect(() => {
    if (Object.keys(detailEvents)) {
      setModalData(detailEvents)
      createDataExcel()
    }
    // eslint-disable-next-line
  }, [detailEvents])

  useEffect(() => {
    if (Object.keys(modalData) && modalData?.participant?.length > 0) {
      console.log(modalData, 'modalData');
      let newParticipant = []
      if (selectedFilterHadir === "Tidak Hadir") {
        setTypeTabelFull(false)
        modalData.participant.forEach((el) => {
          let flag = true
          let regexp = new RegExp(searchName, "gi");
          if (el.statusPeriksa) {
            flag = false
          } else if (
            searchName !== "" && el.name.match(regexp) === null
          ) {
            flag = false
          }
          if (flag) {
            newParticipant.push(el)
          }
        })
      } else {
        setTypeTabelFull(true)
        modalData.participant.forEach((el) => {
          let flag = true
          let regexp = new RegExp(searchName, "gi");
          if (!el.statusPeriksa) {
            flag = false
          } else if (
            searchName !== "" && el.name.match(regexp) === null
          ) {
            flag = false
          } else if (
            selectedFilterHasil !== "Semua Hasil"
            && selectedFilterHasil !== el.RM.lpa.finalExamFinally 
            && selectedFilterHasil !== el.RM.result.swab
          ) {
            flag = false
          }
          if (flag) {
            newParticipant.push(el)
          }
        })
      }
      setDataRender(newParticipant)
    }
    // eslint-disable-next-line
  }, [modalData, searchName, selectedFilterHadir, selectedFilterHasil])


  useEffect(() => {
    if (!modalPdf) {
      setModalPdfFile('')
      setModalPdfType('')
      setModalPdfTitle('')
    }
  }, [modalPdf])

  useEffect(() => {
    if (modalData) {
      setTypeEvent(checkType())
    }
    // eslint-disable-next-line
  }, [modalData])

  const selectFilter = (filtername, filter) => {
    switch (filtername) {
      case "filterHasil":
        setModalFilterHasil(false);
        setSelectedFilterHasil(filter);
        break;

      case "filterHadir":
        setModalFilterHadir(false);
        setSelectedFilterHadir(filter);
        break;

      default:
        break;
    }
  };

  const checkType = () => {
    if (modalData.countResume) {
      if (modalData.countResume["Fit"] !== 0 || modalData.countResume["Unfit"] !== 0 || modalData.countResume["Fit With Medical Note"] !== 0) {
        setItemFilterHasil(["Semua Hasil", "Fit", "Unfit", "Fit With Medical Note"])
        return "MCU"
      } else if (modalData.countResume["Positif"] !== 0 || modalData.countResume["Negatif"] !== 0) {
        setItemFilterHasil(["Semua Hasil", "Positif", "Negatif"])
        return "SWAB"
      } else {
        setItemFilterHasil([])
        return "nonMCU"
      }
    } else {
      return ""
    }
  }

  const openPdf = (file) => {
    if (file !== null && file !== undefined && file.fileUrl) {
      setModalPdfFile(file.fileUrl)
      setModalPdf(true)
      setModalPdfType('pdf')
      setModalPdfTitle('Hasil Pemeriksaan')
    } else {
      dispatch(setErrorMessage("Dokumen tidak valid"))
    }
  }

  const checkBGColor = (result) => {
    if (result === "Unfit" || result === "Negatif") {
      return "#FDDDDD"
    } else if (result === "Fit" || result === "Fit With Medical Note" || result === "Positif") {
      return "#CFF1C1"
    } else {
      return ""
    }
  }

  const checkFontColor = (result) => {
    if (result === "Unfit" || result === "Negatif") {
      return "#D22828"
    } else if (result === "Fit" || result === "Fit With Medical Note" || result === "Positif") {
      return "#1F6700"
    } else {
      return ""
    }
  }

  return (
    <div id="myModal" className="modalResume">
      <div className="modalResume-content">
        <div className="modalResume-header">
          <div>
            DETAIL RESUME {" - " + (modalData?.clinic?.name)?.toUpperCase()}
          </div>
          <div className="row">
            <div style={{ paddingRight: "20px" }}>{"Event : " + modalData?.name + " - " + modalData?.date + " / " + modalData?.time + " WIB"}</div>
            <div
              className="close"
              style={{ cursor: "pointer" }}
              onClick={() => setModal(false)}
            >
              <IcClose />
            </div>
          </div>
        </div>
        <div className="modalResume-Container">
          <div className="row jbetween">
            <div className="modalResume-FilterArea">
              <RoundedSearch setSearchName={setSearchName} searchName={searchName} placeholder={"Cari Karyawan"} />
              <Filter
                filterName={"filterHadir"}
                item={itemFilterHadir}
                selectedItem={selectedFilterHadir}
                modal={modalFilterHadir}
                showModal={() => {
                  setModalFilterHasil(false)
                  modalFilterHadir ? setModalFilterHadir(false) : setModalFilterHadir(true)
                }}
                closeModal={() => setModalFilterHadir(false)}
                selectFilter={selectFilter}
              />
              {
                itemFilterHasil.length > 0 && (
                  <Filter
                    filterName={"filterHasil"}
                    item={itemFilterHasil}
                    selectedItem={selectedFilterHasil}
                    modal={modalFilterHasil}
                    showModal={() => {
                      setModalFilterHadir(false)
                      modalFilterHasil ?
                        setModalFilterHasil(false) :
                        setModalFilterHasil(true)
                    }}
                    closeModal={() => setModalFilterHasil(false)}
                    selectFilter={selectFilter}
                    disabled={typeTableFull ? false : true}
                  />
                )
              }
            </div>
            {
              typeEvent === "MCU" &&
              <JsonToExcel
                title="Download Excel"
                data={dataExcel}
                fileName={`Rekap ${modalData.name}`}
                btnClassName="button-excel"
                
              />
            }
          </div>
          <div className="modalResume-table" style={{ width: "100%", marginBottom: "2%", minHeight: "350px" }}>
            <table >
              <thead className="tablehead" style={{ backgroundColor: "#EBEADD" }}>
                <tr>
                  <th className="columnno">
                    <div
                      className="columntitle"
                    >
                      <div>NO</div>
                    </div>
                  </th>
                  <th className="columnnama" style={{ width: "10%" }}>
                    <div
                      className="columntitle"
                    >
                      <div>NAMA PASIEN</div>
                    </div>
                  </th>
                  <th className="columnnama" style={{ width: "7%" }}>
                    <div
                      className="columntitle"
                    >
                      <div>JENIS KELAMIN</div>
                    </div>
                  </th>
                  {
                    typeTableFull ? (
                      <>
                        {
                          typeEvent === "MCU" && (
                            <>
                              <th className="columnnama">
                                <div
                                  className="columntitle"
                                >
                                  <div>KESIMPULAN</div>
                                </div>
                              </th>
                              <th className="columnnama">
                                <div
                                  className="columntitle"
                                >
                                  <div>SARAN</div>
                                </div>
                              </th>
                            </>
                          )
                        }
                        {
                          (typeEvent === "MCU" || typeEvent === "SWAB") && (
                            <th className="columnnama" style={{ width: "10%" }}>
                              <div
                                className="columntitle"
                              >
                                <div>HASIL</div>
                              </div>
                            </th>
                          )
                        }

                        <th className="columnno">
                          <div
                            className="columntitle"
                          >
                            <div>AKSI</div>
                          </div>
                        </th>
                      </>
                    ) : (
                      <th className="columnnama">
                        <div
                          className="columntitle"
                        >
                          <div>STATUS</div>
                        </div>
                      </th>
                    )
                  }

                </tr>
              </thead>
              <tbody>
                {dataRender && dataRender.length > 0 && dataRender.map((el, idx) => {
                  return (
                    <tr className="tablebody" key={el._id}>
                      <th className="columnbodyno">{idx + 1}</th>
                      <th className="columnbodynama" style={{ width: "10%" }}>
                        <div>

                          {el.name.length > 15
                            ? el.name.slice(0, 15) + " ..."
                            : el.name}
                        </div>
                        <div>{el.dob}</div>
                      </th>
                      <th className="columnbodynama" style={{ width: "7%" }}>{el.gender}</th>
                      {
                        typeTableFull ? (
                          <>
                            {
                              typeEvent === "MCU" && (
                                <>
                                  <th className="columnbodynama" dangerouslySetInnerHTML={{ __html: el.RM?.lpa?.MCUPhysicalExamID?.result }}></th>
                                  <th className="columnbodynama" dangerouslySetInnerHTML={{ __html: el.RM?.lpa?.MCUPhysicalExamID?.suggestion }}></th>
                                  <th className="columnbodynama" style={{ width: "10%" }}>
                                  <div style={{
                                    fontSize: "12px",
                                    padding: "8px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    backgroundColor: checkBGColor(el.RM?.lpa?.finalExamFinally),
                                    color: checkFontColor(el.RM?.lpa?.finalExamFinally),
                                    width: "fit-content",
                                    borderRadius: "10px"
                                  }}>
                                    { el.RM?.lpa?.finalExamFinally }
                                  </div>
                                </th>
                                </>
                              )
                            }
                            {
                               typeEvent === "SWAB" && (
                                <th className="columnbodynama" style={{ width: "10%" }}>
                                  <div style={{
                                    fontSize: "12px",
                                    padding: "8px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    backgroundColor: checkBGColor(el.RM?.result?.swab),
                                    color: checkFontColor(el.RM?.result?.swab),
                                    width: "fit-content",
                                    borderRadius: "10px"
                                  }}>
                                    { el.RM?.result.swab }
                                  </div>
                                </th>
                               )
                            }
                            
                            <th className="columnbodyno" style={{ color: el.statusPeriksa ? "green" : "", cursor: el.statusPeriksa ? "pointer" : "", }}
                              onClick={() => {
                                  if(el.statusPeriksa){
                                    openPdf(el.file)
                                  }
                                }}>
                              <IcDocument />
                            </th>
                          </>
                        ) : (
                          <th className="columnbodynama" style={{ color: "#D22828" }}>Tidak Hadir</th>
                        )
                      }
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {modalPdf && (
          <ModalPDFViewer title={modalPdfTitle} modal={setModalPdf} url={modalPdfFile} type={modalPdfType} />
        )}
      </div>
    </div>
  );
}
