import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/login.css";

import { login, setErrorMessage, setSuccessMessage } from '../../store/actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login() {
  const dispatch = useDispatch()
  const { errorMessage, successMessage } = useSelector(state => state.messageState)
  const [isInactive, setIsInactive] = useState(false)
  const [payload, setPayload] = useState({
    email: '',
    password: ''
  })

  const loginHandle = () => {
    setIsInactive(false)
    if(payload.email === ''){return toast('Email can not be empty')}
    if(payload.password === ''){return toast('Password can not be empty')}
    dispatch(login(payload))
  }

  useEffect(() => {
    if(errorMessage){
      if(errorMessage === "Akun Tidak Aktif"){
        setIsInactive(true)
      }
      dispatch(setErrorMessage(''))
    }
    if(successMessage){
      dispatch(setSuccessMessage(''))
    }
    // eslint-disable-next-line
  },[errorMessage, successMessage])

  return (
    <div className="background">
      <ToastContainer  />
      <div className="card">
        <div className="content">
          <div className="logo">
            <img
              src={require("../../asset/png/logoMedQCare.png")}
              width="240"
              height="45"
              alt="logo medqcare"
            />
          </div>
          <div className="inputBox">
            <input type="text" placeholder="Email" 
              onChange={(e) => {
                setPayload({
                  ...payload,
                  email: e.target.value
                })
              }}
            />
          </div>
          <div className="inputBox">
            <input type="password" placeholder="Password" 
              onKeyUp={(e) => {
                if(e.keyCode === 13){
                  loginHandle()
                }
              }}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  password: e.target.value
                })
              }}
            />
          </div>
          {
            isInactive && (
              <div className="warning">
                <div>Akun anda belum diaktivasi,</div>
                <div>Silahkan hubungi Applimetis untuk Aktifasi akun</div>
             </div>
            )
          }
          <a className="leftDirection" href="/reset-password">Lupa password?</a>
          <div className="buttonSubmit" onClick={() => loginHandle()}>Masuk</div>
          <div className="register">
            <div>Perusahaan anda belum terdaftar?</div>
            <a href="/register">Daftar Sekarang</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
